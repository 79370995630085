<template>
  <div class="app-container">
    <eHeader  :query="query" :platformoptions="listPlatformOptions" :signInfooptions="listSignInfoOptions" :productoptions="listProductOptions" :smsTypeoptions="smsTypeoptions" :sendTypeoptions="sendTypeoptions"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="configId" label="ID"/>
      <el-table-column prop="configName" label="配置名称"/>
      <el-table-column prop="configCode" label="配置编码"/>
      <el-table-column prop="productName" label="产品"/>
      <el-table-column prop="platformName" label="短信平台"/>
      <el-table-column prop="signName" label="签名"/>
      <el-table-column prop="smsTypeName" label="短信类型">
        <template slot-scope="scope">
          <span>{{ getsmsTypeName(scope.row.smsType) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="sendTypeName" label="短信发送类型">
        <template slot-scope="scope">
          <span>{{ getsendTypeName(scope.row.sendType) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作"  align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','MERCHANT_ALL','MERCHANT_EDIT'])" :data="scope.row" :sup_this="sup_this"  :platformoptions="listPlatformOptions" :signInfooptions="listSignInfoOptions" :productoptions="listProductOptions" :smsTypeoptions="smsTypeoptions" :sendTypeoptions="sendTypeoptions"/>
          <el-popover
            v-if="checkPermission(['ADMIN','USER_ALL','USER_DELETE'])"
            :ref="scope.row.configId"
            placement="top"
            width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.configId].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="softDelete(scope.row)">确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initSmsData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/sms/smsconfig/header'
import edit from '@/components/sms/smsconfig/edit'
import { updateSmsConfig, listSmsConfig, listAllPlatform, listAllSignInfo, listAllProduct } from '@/api/sms'
export default {
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false, sup_this: this,formData:{},
      listPlatformOptions: [],
      listSignInfoOptions: [],
      listProductOptions: [],
      smsTypeoptions: [{
        smsType: '0',
        smsTypeName: '营销类'
      }, {
        smsType: '1',
        smsTypeName: '通知类'
      }, {
        smsType: '2',
        smsTypeName: '验证码'
      }],
      sendTypeoptions: [{
        sendType: '0',
        sendTypeName: '单发'
      }, {
        sendType: '1',
        sendTypeName: '批量'
      }],
    }
  },
  created() {
    this.queryPlatformOption()
    this.querySignInfoOption()
    this.queryProductOption()
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = '/jrqsms/api/smsConfig/listSmsConfig'
      const query = this.query
      const configName = query.configName
      const configCode = query.configCode
      this.formData = { currentPage: this.page, pageSize: this.size }
      if (configName !== '' && configName !== null) { this.formData['configName'] = configName }
      if (configCode !== '' && configCode !== null) { this.formData['configCode'] = configCode } 
      this.params = JSON.stringify(this.formData)
      return true
    },
    queryPlatformOption(){
     const params = JSON.stringify()
     listAllPlatform(params).then(res => {
        this.listPlatformOptions = res.data
      })
    },
    querySignInfoOption(){
     const params = JSON.stringify()
     listAllSignInfo(params).then(res => {
        this.listSignInfoOptions = res.data
      })
    },
    queryProductOption(){
     const params = JSON.stringify()
     listAllProduct(params).then(res => {
        this.listProductOptions = res.data
      })
    },
    getsmsTypeName(smsType){
      var smsTypeName = ""
      if(smsType !== '' && smsType !== null){
        var smsType_list = smsType.split(',')
        for (var i = smsType_list.length - 1; i >= 0; i--) {
          smsTypeName = smsTypeName +this.smsTypeoptions[i].smsTypeName
          if(i!=0){
            smsTypeName = smsTypeName +','
          }
        }
      }
      return smsTypeName
    },
    getsendTypeName(sendType){
      var sendTypeName = ""
      if(sendType !== '' && sendType !== null){
        var sendType_list = sendType.split(',')
        for (var i = sendType_list.length - 1; i >= 0; i--) {
          sendTypeName = sendTypeName +this.sendTypeoptions[i].sendTypeName
          if(i!=0){
            sendTypeName = sendTypeName +','
          }
        }
      }
      return sendTypeName
    },
    softDelete(info) {
      this.delLoading = true
      info.status="1"
      this.formData = info
      this.params = JSON.stringify(this.formData)
      updateSmsConfig(this.params).then(res => {
        this.delLoading = false
        this.$refs[info.configId].doClose()
        this.init()
        this.$notify({
          title: '删除成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[info.configId].doClose()
        console.log(err.response.data.message)
      })
    }    
  }
}
</script>

<style scoped>

</style>
