<template>
  <div>
    <el-button size="mini" type="success" @click="to">编辑</el-button>
    <eForm ref="form"  :sup_this="sup_this" :is-add="false" :platformoptions="platformoptions" :signInfooptions="signInfooptions" :productoptions="productoptions" :smsTypeoptions="smsTypeoptions" :sendTypeoptions="sendTypeoptions"/>
  </div>
</template>
<script>
import eForm from './form'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    // index.vue 的this 可用于刷新数据
    sup_this: {
      type: Object,
      required: true
    },
    platformoptions: {
      type: Array,
      required: true
    },
    signInfooptions: {
      type: Array,
      required: true
    },
    productoptions: {
      type: Array,
      required: true
    },
    smsTypeoptions: {
      type: Array,
      required: true
    },
    sendTypeoptions: {
      type: Array,
      required: true
    },
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.form ={ configId:this.data.configId,configName: this.data.configName, configCode: this.data.configCode, productName: this.data.productName, productCode: this.data.productCode, platformName: this.data.platformName, platformCode: this.data.platformCode, signName: this.data.signName, signCode: this.data.signCode, smsType: this.data.smsType, sendType: this.data.sendType}
      _this.platformoptions=this.platformoptions
      _this.signInfooptions=this.signInfooptions
      _this.productoptions=this.productoptions
      if(this.data.smsType!=null && this.data.smsType!=''){
        _this.smsType_list=this.data.smsType.split(',')
      }
      _this.smsTypeoptions=this.smsTypeoptions
      if(this.data.sendType!=null && this.data.sendType!=''){
        _this.sendType_list=this.data.sendType.split(',')
      }
      _this.sendTypeoptions=this.sendTypeoptions
      _this.dialog = true
    }
  }
}
</script>

<style scoped>
  div{display: inline-block;margin-right: 3px;}
</style>
