<template>
  <el-dialog :visible.sync="dialog" :title="isAdd ? '新增短信发送配置' : '编辑短信发送配置'" append-to-body width="700px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="120px">
      <el-form-item label="配置名称" prop="configName">
        <el-input v-model="form.configName" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="配置编码" prop="configCode">
        <el-input v-model="form.configCode" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="产品类型" prop="productCode">
        <el-select v-model="form.productCode" placeholder="请选择产品">
        <el-option
          v-for="item in productoptions"
          :key="item.productCode"
          :label="item.productName"
          :value="item.productCode">
        </el-option>
      </el-select>
      </el-form-item>
      <el-form-item label="短信类型" prop="smsType">
        <el-select v-model="smsType_list" multiple placeholder="请选择短信类型">
        <el-option
          v-for="item in smsTypeoptions"
          :key="item.smsType"
          :label="item.smsTypeName"
          :value="item.smsType">
        </el-option>
      </el-select>
      </el-form-item>
      <el-form-item label="短信发送" prop="sendType">
        <el-select v-model="sendType_list" multiple placeholder="请选择短信类型">
        <el-option
          v-for="item in sendTypeoptions"
          :key="item.sendType"
          :label="item.sendTypeName"
          :value="item.sendType">
        </el-option>
      </el-select>
      </el-form-item>
      <el-form-item label="短信平台" prop="platformCode">
        <el-select v-model="form.platformCode" placeholder="请选择短信平台">
        <el-option
          v-for="item in platformoptions"
          :key="item.platformCode"
          :label="item.platformName"
          :value="item.platformCode">
        </el-option>
      </el-select>
      </el-form-item>
      <el-form-item label="短信签名" prop="signCode">
        <el-select v-model="form.signCode" placeholder="请选择短信签名">
        <el-option
          v-for="item in signInfooptions"
          :key="item.signCode"
          :label="item.signName"
          :value="item.signCode">
        </el-option>
      </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { insertSmsConfig, updateSmsConfig } from '@/api/sms'
import { parseTime } from '@/utils/index'

//const cityOptions = ['上海', '北京', '广州', '深圳'];
export default {
  name: 'Form',
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
    productoptions: {
      type: Array,
      required: true
    },
    platformoptions: {
      type: Array,
      required: true
    },
    signInfooptions: {
      type: Array,
      required: true
    },
    smsTypeoptions: {
      type: Array,
      required: true
    },
    sendTypeoptions: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      smsType_list: '',
      sendType_list: '',
      dialog: false, loading: false, form: {configName: '',productCode: '', signName: '',configCode: '', sendType: '', smsType: '', platformName: '', signCode: '', platformCode: '', productName: ''},
      rules: {
        // configName: [
        //   { required: true, message: '请输入配置名称', trigger: 'blur' }
        // ],
        // productCode: [
        //   { required: true, message: '请选择产品编码', trigger: 'blur' }
        // ],
        // signName: [
        //   { required: true, message: '请选择短信签名', trigger: 'blur' }
        // ],
        // configCode: [
        //   { required: true, message: '请输入配置编码', trigger: 'blur' }
        // ],
        // sendType: [
        //   { required: true, message: '请选择短信发送类型', trigger: 'blur' }
        // ],
        // smsType: [
        //   { required: true, message: '请选择短信类型', trigger: 'blur' }
        // ],
        // platformName: [
        //   { required: true, message: '请选择短信平台名称', trigger: 'blur' }
        // ],
        // signCode: [
        //   { required: true, message: '请输入短信签名编码', trigger: 'blur' }
        // ],
        // platformCode: [
        //   { required: true, message: '请选择短信平台编码', trigger: 'blur' }
        // ],
        // productName: [
        //   { required: true, message: '请输入短信签名', trigger: 'blur' }
        // ],
      }
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.isAdd) {
            this.doAdd()
          } else {
            this.doEdit()
          } 
        } else {
          return false
        }
      })
    },
    doAdd() {
      this.formatData()
      insertSmsConfig(this.params).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      this.formatData()
      updateSmsConfig(this.params).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    formatData(){
      this.form.smsType=''
      for (var i = this.smsType_list.length - 1; i >= 0; i--) {
        this.form.smsType = this.form.smsType + this.smsType_list[i]
        if(i!=0){
          this.form.smsType = this.form.smsType + ','
        }
      }
      this.form.sendType=''
      for (var i = this.sendType_list.length - 1; i >= 0; i--) {
        this.form.sendType = this.form.sendType + this.sendType_list[i]
        if(i!=0){
          this.form.sendType = this.form.sendType + ','
        }
      }
      this.formData = this.form 
      this.params = JSON.stringify(this.formData)
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.smsType_list = []
      this.sendType_list = []
      this.form = { configName: '',productCode: '', signName: '', configCode: '', sendType: '', smsType: '', platformName: '', signCode: '', platformCode: '', productName: ''}
    }
  }
}
</script>

<style scoped>

</style>
